/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*
- [Folder Structure for API's - PedroTech](https://www.youtube.com/watch?v=oNlMrpnUSFE)
- [Clean Architecture - Dev Mastery](https://www.youtube.com/watch?v=CnailTcJV_U)*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    hr: "hr",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Directory Structure Example"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.coreycleary.me/project-structure-for-an-express-rest-api-when-there-is-no-standard-way"
  }, "Express REST API project structure")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example One")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, " src/\n\t ├── app.js --- app entry point\n\t ├── /api --- controller layer: api routes\n\t ├── /config --- config settings, env variables\n\t ├── /services --- service layer: business logic\n\t ├── /models --- data access layer: database models\n\t ├── /scripts --- miscellaneous NPM scripts\n\t ├── /subscribers --- async event handlers\n\t └── /test --- test suites\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example Two")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "\n\tsrc/\n\t\t|- controllers/ -> logic before saving to the db, check permission, etc..\n\t\t|- db/\n\t\t\t|-- models/ -> the models\n\t\t\t|-- config/ -> config about the db, connection to the db etc\n\t\t\t|-- migrations/ -> all the migrations file for the db\n\t\t|- helpers/ -> helpers function like sum, total, pluralize, etc\n\t\t|- routes/ -> all the rest api route, where they take a controller as callback\n\t\t|- services/ -> stripe, aws s3 etc\n\t\t|- test -> all your test\n\t\t|-package.json -> all your dependencies\n\t\t|-index.js -> where everything start, your server instance etc\n\n")), "\n", React.createElement(_components.h2, null, "MVC"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "MVC Example - intermediate")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "\n\tsrc/\n\t\t|- api/ --- everything related to routes\n\t\t\t|- controllers/ --- valiadate data\n\t\t\t|- models/ --- models and schemas\n\t\t\t|- helpers/ --- generic helpers functions that maybe used in many places\n\t\t\t|- services/ --- business logic, data transforms and calls to the database\n\t\t\t|- routes/ --- routes\n\t\t\t|- middlewares/ --- routes\n\t\t\t|- validation/ ---\n\t\t|-- config/ --- config files\n\t\t|- test\n\t\t|-package.json\n\t\t|-index.js\n\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example - Startup/Enterprise")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "use typeScript"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "\n\ttest/\n\tsrc/\n\t\t|- api/ --- everything related to routes\n\t\t\t|- controllers/ --- valiadate data\n\t\t\t|- models/ --- models and schemas\n\t\t\t|- helpers/ --- generic helpers functions that maybe used in many places\n\t\t\t|- services/ --- business logic, data transforms and calls to the database\n\t\t\t|- routes/ --- routes\n\t\t\t|- middlewares/ --- routes\n\t\t\t|- validation/ ---\n\t\t|-- config/ --- config files\n\t\t|-package.json\n\t\t|-index.js\n\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Layers"), "\n", React.createElement(_components.h2, null, "Data Layer"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.logrocket.com/nodejs-expressjs-postgresql-crud-rest-api-example/"
  }, "node|express|postgress - logrocket")), "\n", React.createElement(_components.p, null, "| Main Layer           | Type                   | What logic goes here?                                                                                                                                                                           |\n| -------------------- | ---------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |\n| HTTP logic layer     | Routes + Controllers   | Routes - handle the HTTP requests that hits the API and route them to appropriate controller(s) Contollers - take request object, pull out data from request, validate, then send to service(s) |\n| Business logic layer | Services + Data Access | Contains the business logic, derived from business and technical requirements, as well as how we access our data stores**                                                                     |\n|                      |                        |                                                                                                                                                                                                 |"), "\n", React.createElement(_components.h3, null, "ORM"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://sequelize.org/v7/manual/model-basics.html#concept"
  }, React.createElement(_components.strong, null, "Sequelize"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.codementor.io/@mirko0/how-to-use-sequelize-with-node-and-express-i24l67cuz"
  }, "How to use sequelize with node|express")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stackabuse.com/using-sequelize-orm-with-nodejs-and-express/"
  }, "using sequelize orm with node|express - stack abuse")), "\n"), "\n", React.createElement(_components.h3, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Articles")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://softwareontheroad.com/ideal-nodejs-project-structure/"
  }, "softwareontheroad - ideal nodejs project structure ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://scotch.io/tutorials/keeping-api-routing-clean-using-express-routers"
  }, "Scotch.io - Keeping API routing clean using Express Routes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/searsaw/express-routing-example/"
  }, "Scotch.io - example")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bezkoder.com/node-express-sequelize-postgresql/"
  }, "React, Node Express, Postgres walk through - BezKoder"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bezkoder.com/react-node-express-postgresql/#Define_the_Sequelize_Model"
  }, "Sequelize model")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.taniarascia.com/node-express-postgresql-heroku/"
  }, "Node|Express|Postgres - REST API ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.logrocket.com/build-rest-api-node-express-mysql/"
  }, "Node|Express|MySQL - REST API")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Videos")), "\n", "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "oNlMrpnUSFE"
  })), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "CnailTcJV_U"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
